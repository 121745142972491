import React, { useState, useEffect, useMemo } from 'react'
import { uniq, uniqBy, compact } from 'lodash'
import SVG from 'react-inlinesvg'
import {
  useCounter,
  parseQuery,
  useApiGet,
  varClass,
  map,
  capitalize,
  navigateTo,
  articlePath,
  tagColors,
  articleGroupLabels,
  getCategoryKey,
  articleSort,
} from '../shared'
import { Header, Loader } from '../layout'

export function CategoryPage(props) {
  const initialCategory = (props.params['*'] || '').split('/')[0]
  const initialSubCategory = (props.params['*'] || '').split('/')[1]
  const query = useMemo(() => parseQuery(props.location.search), [])

  const [selectedGroup, setSelectedGroup] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(
    initialCategory !== 'all' ? initialCategory : ''
  )
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    initialSubCategory || ''
  )

  useEffect(() => {
    if (query.grp || !initialCategory) {
      navigateTo('/')
    }
  }, [query])

  useEffect(() => {
    let newPath = '/cat'
    if (selectedCategory) {
      newPath += `/${selectedCategory}`
    } else {
      newPath += '/all'
    }
    if (selectedSubCategory) {
      newPath += `/${selectedSubCategory}`
    }

    window.history.pushState({}, '', newPath)
  }, [selectedCategory, selectedSubCategory])

  const data = useApiGet('/docs')

  const loaded = data?.articles && Array.isArray(data.articles)

  const categories =
    loaded &&
    uniqBy(
      data.articles
        .filter((article) => {
          if (selectedGroup) {
            if (article.group !== selectedGroup) return false
          }
          return true
        })
        .filter((article) => article.category)
        .map((article) => article.category),
      (category) => category.toLowerCase()
    )
  const topArticles =
    loaded && data.articles.filter((article) => article.isPopular)
  const articles =
    loaded &&
    data.articles.filter((article) => {
      if (selectedGroup) {
        if (article.group !== selectedGroup) return false
      }
      if (selectedCategory) {
        if (article.category?.toLowerCase() !== selectedCategory) return false
      }
      if (selectedSubCategory) {
        if (article.subCategory?.toLowerCase() !== selectedSubCategory)
          return false
      }
      return true
    })

  if (!loaded) return <Loader />

  return (
    <main className="cat">
      {loaded && (
        <>
          <Header {...props} />
          <div className="page-category-container">
            <Sidebar
              articles={data.articles}
              category={initialCategory}
              subCategory={initialSubCategory}
            />

            <div className="page-category">
              <CategoriesWithArticles
                {...{
                  categories,
                  selectedCategory,
                  setSelectedCategory,
                  selectedSubCategory,
                  setSelectedSubCategory,
                  articles,
                }}
              />

              {/* <TopArticles {...{ loaded, topArticles }} /> */}

              <div className="hero2">
                <div className="hero-content">
                  <div className="row">
                    <div>
                      <div className="h1">Do you want a helping hand?</div>
                      <div className="h2">
                        Get in touch with our support team and get proper
                        technical guidance
                      </div>
                      <div className="h2 m-t-30">
                        Write us at{' '}
                        <a
                          href="mailto:support@heylink.com"
                          className="email-link"
                        >
                          support@heylink.com
                        </a>
                      </div>
                    </div>

                    <div>
                      {/*
                    <div className="card">
                      <div className="card-avatar" />
                      <div className="card-name">Louise Bønløkke</div>
                      <div className="card-title">Technical Assistance</div>
                      <div className="card-button-container">
                        <button className="card-button">
                          <span>Support</span>
                        </button>
                      </div>
                    </div>
                    */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </main>
  )
}

function TopArticles(props) {
  const { loaded, topArticles } = props

  return (
    <div className="hero">
      <div className="hero-content">
        <div className="h1">Explore our documentations</div>
        <div className="h2">Most Popular Guides</div>
      </div>
      <div className="hero-articles">
        {loaded &&
          map(topArticles, (article) => (
            <TopArticle key={article.uuid} article={article} />
          ))}
      </div>
    </div>
  )
}

function TopArticle(props) {
  const { article } = props
  const { category } = article

  const tagColor = tagColors[category.toLowerCase()]

  return (
    <div className="hero-article">
      <div>
        {category && (
          <div
            className={varClass({
              'tag': true,
              [`text-${tagColor}`]: !!tagColor,
            })}
          >
            {category}
          </div>
        )}
        <div className="article-title">{article.shortTitle}</div>
      </div>
      <a className="article-link" href={articlePath(article)}>
        Read
      </a>
    </div>
  )
}

function CategoriesWithArticles(props) {
  const {
    categories,
    selectedCategory,
    setSelectedCategory,
    selectedSubCategory,
    setSelectedSubCategory,
    articles,
  } = props
  return (
    <div className="container">
      <Categories
        {...{
          categories,
          selectedCategory,
          setSelectedCategory,
          selectedSubCategory,
          setSelectedSubCategory,
        }}
      />

      <div className="articles">
        {map(articles, (article) => (
          <Article key={article.uuid} article={article} />
        ))}
      </div>
    </div>
  )
}

function Categories(props) {
  const {
    categories,
    selectedCategory,
    setSelectedCategory,
    selectedSubCategory,
    setSelectedSubCategory,
  } = props

  const [render, rerender] = useCounter()
  useEffect(() => {
    rerender()
  }, [selectedCategory])

  return (
    <div className="articles-categories">
      <button
        className={varClass({
          'articles-category': true,
          'active': !selectedCategory,
        })}
        onClick={() => {
          setSelectedCategory('')
          setSelectedSubCategory('')
        }}
      >
        <span>All</span>
      </button>
      {map(categories, (key) => (
        <button
          key={key}
          className={varClass({
            'articles-category': true,
            'active':
              key.toLowerCase() === selectedCategory && !selectedSubCategory,
          })}
          onClick={() => {
            setSelectedCategory(key.toLowerCase())
            setSelectedSubCategory('')
          }}
        >
          <span>{key}</span>
        </button>
      ))}
    </div>
  )
}

function Article(props) {
  const { article } = props
  const { category } = article

  const tagColor = tagColors[category.toLowerCase()]

  return (
    <a className="article" href={articlePath(article)}>
      {category && (
        <div
          className={varClass({
            'tag': true,
            [`text-${tagColor}`]: !!tagColor,
          })}
        >
          {category}
        </div>
      )}
      <div className="article-title">{article.shortTitle}</div>
    </a>
  )
}

function Sidebar(props) {
  const { category, subCategory, articles } = props

  const [openCategories, setOpenCategories] = useState(() =>
    compact([category, subCategory])
  )
  const toggleOpenCategory = (category) => {
    const canonicalCategory = category.toLowerCase()
    const newOpenCategories = [...openCategories]
    if (newOpenCategories.includes(canonicalCategory)) {
      newOpenCategories.splice(newOpenCategories.indexOf(canonicalCategory), 1)
    } else {
      newOpenCategories.push(canonicalCategory)
    }
    setOpenCategories(newOpenCategories)
  }
  const isCategoryOpen = (category) => {
    const canonicalCategory = category.toLowerCase()
    return openCategories.includes(canonicalCategory)
  }

  const categoryMap = useMemo(() => {
    if (!articles) return {}
    const result = {}
    articles.forEach((article) => {
      result[article.category] = result[article.category] || []
      if (article.subCategory) {
        result[article.category].push(article.subCategory)
        result[article.category] = uniq(result[article.category].sort())
      }
    })
    return result
  }, [articles])

  if (!articles) {
    return null
  }

  const topCategories = Object.keys(categoryMap).sort()

  const categoryArticles = articles.reduce((result, article) => {
    const categoryKey = getCategoryKey(article)
    result[categoryKey] = result[categoryKey] || []
    result[categoryKey].push(article)
    return result
  }, {})

  return (
    <aside>
      <div className="sticky">
        <a className="h1" href="/">
          All articles
        </a>
        <ul>
          {map(topCategories, (category) => (
            <li key={category}>
              {!!category ? (
                <>
                  <button
                    onClick={() => toggleOpenCategory(category)}
                    className={varClass({
                      'open': isCategoryOpen(category),
                    })}
                  >
                    {category}
                  </button>
                  <ul
                    className={varClass({
                      'open': isCategoryOpen(category),
                    })}
                  >
                    {map(
                      categoryArticles[category]?.sort(articleSort),
                      (article) => (
                        <li key={article.uuid}>
                          <a href={articlePath(article)}>
                            {article.shortTitle}
                          </a>
                        </li>
                      )
                    )}

                    {map(categoryMap[category], (subCategory) => (
                      <li key={subCategory}>
                        <button
                          onClick={() => toggleOpenCategory(subCategory)}
                          className={varClass({
                            'open': isCategoryOpen(subCategory),
                          })}
                        >
                          {subCategory}
                        </button>
                        <ul
                          className={varClass({
                            'open': isCategoryOpen(subCategory),
                          })}
                        >
                          {map(
                            categoryArticles[
                              `${category}::${subCategory}`
                            ]?.sort(articleSort),
                            (article) => (
                              <li key={article.uuid}>
                                <a href={articlePath(article)}>
                                  {article.shortTitle}
                                </a>
                              </li>
                            )
                          )}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  {map(
                    categoryArticles[category]?.sort(articleSort),
                    (article) => (
                      <div key={article.uuid}>
                        <a href={articlePath(article)}>{article.shortTitle}</a>
                      </div>
                    )
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </aside>
  )
}
