import React from 'react'
import { Layout } from '../components/layout'
import { CategoryPage } from '../components/category'

const CategoryPageContainer = (props) => {
  return (
    <Layout>
      <CategoryPage {...props} />
    </Layout>
  )
}

export default CategoryPageContainer
